import React, { useEffect } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  Loader,
  Radio,
  Button,
  Icon,
  Checkbox,
  Modal,
  Table,
  Dropdown,
  Select,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import "./SalesPolicy.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "bootstrap/dist/css/bootstrap.css";

const axios = require("axios");

export default function SalesPolicy() {
  const [emp_id, set_emp_id] = React.useState(localStorage.getItem("emp_id"));
  const [loading, set_loading] = React.useState(true);
  const [asmloading, set_asmloading] = React.useState(true);
  const [CSMNewloading, set_CSMNewloading] = React.useState(true);
  const [data_list, set_data_list] = React.useState([]);
  const [data_list2, set_data_list2] = React.useState([]);
  const [header, set_header] = React.useState([]);
  const [header2, set_header2] = React.useState([]);
  const [cell_data, cell_cell_data] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [opennewCSM, setOpennewCSM] = React.useState(false);
  const [month, set_month] = React.useState("");
  const [user, set_user] = React.useState("");
  const [type, set_type] = React.useState(0);
  const [paidornot, set_paidornot] = React.useState("nonpaid");
  const [isPaidAsm, set_isPaidAsm] = React.useState(0);
  const [isPrevious, set_isPrevious] = React.useState(0);
  const [cceorasm, set_cceorasm] = React.useState("cce");
  const [headerMonth, setHeaderMonth] = React.useState("");
  const [headerMonth1, setHeaderMonth1] = React.useState("");
  const [asmNewheaderMonth, setAsmNewHeaderMonth] = React.useState("");
  const [asmNewheaderMonth1, setAsmNewHeaderMonth1] = React.useState("");
  const [CSMNewheaderMonth, setCSMNewHeaderMonth] = React.useState("");
  const [CSMNewheaderMonth1, setCSMNewHeaderMonth1] = React.useState("");

  const [moveMonths, setMoveMonths] = React.useState(1);
  const [moveMonthsForASM, setMoveMonthsForASM] = React.useState(1);
  const [moveMonthsForNewASM, setMoveMonthsForNewASM] = React.useState(1);
  const [moveMonthsForNewCSM, setmoveMonthsForNewCSM] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [CSMNewData, setCSMNewData] = React.useState([]);
  const [showNoRecords, setShowNoRecords] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [asmModalopen, setasmModalOpen] = React.useState(false);
  const [csmModalopen, setcsmModalOpen] = React.useState(false);
  const [selectedTarget, setSelectedTarget] = React.useState("");
  const [selectedPercentage, setSelectedPercentage] = React.useState("");
  const [selectedIncentive, setSelectedIncentive] = React.useState("");
  const [selectedSalary, setSelectedSalary] = React.useState(0);
  const [
    selectedFirstPartIncentive,
    setSelectedFirstPartIncentive,
  ] = React.useState(0);
  const [
    selectedSecondPartIncentive,
    setSelectedSecondPartIncentive,
  ] = React.useState(0);
  const [
    selectedThirdPartIncentive,
    setSelectedThirdPartIncentive,
  ] = React.useState(0);
  const [selectedReportingASM, setSelectedReportingASM] = React.useState(0);

  const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

  const handleSelectChange = (event) => {
    // Update the selected value state variable
    set_cceorasm(event.target.value);
  };

  const resetStates = () => {
    setSelectedIncentive("");
    setSelectedPercentage("");
    setSelectedTarget("");
    setSelectedFirstPartIncentive(0);
    setSelectedSecondPartIncentive(0);
    setSelectedThirdPartIncentive(0);
    setSelectedReportingASM(0);
    setShowPopup(false);
  };

  const calculateIncentiveForCSMNewPli = (
    target,
    percentage,
    incentive,
    csm_plus_incentive,
    achieved,
  ) => {
    debugger;
    let incentiveValue = 0;
    if (incentive > 0 && percentage == 75) {
      const reportingASM = Math.round(0.75 * 0.0125 * target);
      setSelectedReportingASM(reportingASM);
    }
    if (percentage > 75 && percentage <= 100) {
      const firstPart = Math.round(0.75 * 0.0125 * target);
      setSelectedFirstPartIncentive(firstPart);
      const secondPart = Math.round(((percentage - 75) / 100) * 0.025 * target);
      incentiveValue = firstPart + secondPart;
      setSelectedSecondPartIncentive(secondPart);
    }
    if (percentage > 100) {
      const firstPart = Math.round(0.75 * 0.0125 * target);
      setSelectedFirstPartIncentive(firstPart);
      const secondPart = Math.round((25 / 100) * 0.025 * target);
      setSelectedSecondPartIncentive(secondPart);
      const thirdPart = Math.round((achieved - target) * 0.05);
      setSelectedThirdPartIncentive(thirdPart);
      incentiveValue = Math.round(firstPart + secondPart + thirdPart);
    }
    if (csm_plus_incentive > 0) {
      setSelectedReportingASM(csm_plus_incentive);
    }
  };

  const calculateIncentive = (
    target,
    percentage,
    incentive,
    salary,
    selfIncentive
  ) => {
    debugger;
    let incentiveValue = 0;
    if (incentive > 0 && percentage < 75) {
      const reportingASM = Math.round(3 * salary + incentive);
      setSelectedReportingASM(reportingASM);
    }
    if (percentage > 75 && percentage <= 100) {
      const firstPart = Math.round(0.75 * 0.0125 * target);
      setSelectedFirstPartIncentive(firstPart);
      const secondPart = Math.round(((percentage - 75) / 100) * 0.025 * target);
      incentiveValue = firstPart + secondPart;
      setSelectedSecondPartIncentive(secondPart);
    }
    if (percentage > 100) {
      const firstPart = Math.round(0.75 * 0.0125 * target);
      setSelectedFirstPartIncentive(firstPart);
      const secondPart = Math.round((25 / 100) * 0.025 * target);
      setSelectedSecondPartIncentive(secondPart);
      const thirdPart = Math.round(((percentage - 100) / 100) * 0.05 * target);
      setSelectedThirdPartIncentive(thirdPart);
      incentiveValue = Math.round(firstPart + secondPart + thirdPart);
    }
    if (selfIncentive - incentive < -1) {
      setSelectedReportingASM(Math.round(incentive - selfIncentive));
    }
  };

  // Function to toggle popup visibility and set selected incentive
  const togglePopup = (
    target,
    percentage,
    incentive,
    salary,
    selfIncentive
  ) => {
    setSelectedTarget(target);
    setSelectedPercentage(percentage);
    setSelectedIncentive(incentive);
    setSelectedSalary(3 * salary);
    calculateIncentive(target, percentage, incentive, salary, selfIncentive);
    setasmModalOpen(true);
  };


  const togglePopupForNewCSM = (
    target,
    percentage,
    incentive,
    csm_plus_incentive,
    achieved
  ) => {
    setSelectedTarget(target);
    setSelectedPercentage(percentage);
    setSelectedIncentive(incentive);
    calculateIncentiveForCSMNewPli(target, percentage, incentive, csm_plus_incentive, achieved);
    setcsmModalOpen(true);
  };


  const parseInteger = (value) => {
    // Check if the value is null
    if (value === null) {
      return 0;
    }

    // Check if the value contains a comma
    if (value.includes(",")) {
      // Remove commas and convert to integer
      return parseInt(value.replace(/,/g, ""), 10);
    } else {
      // If no comma, just return the value
      return value;
    }
  };

  const fetchData = async () => {
    const response = await fetch("yusuf/ASMNewPolicy");
    const result = await response.json();
    return result;
  };

  const addMonths = (input, months) => {
    const date = new Date(input);

    date.setDate(1);
    date.setMonth(date.getMonth() + months - 1);

    date.setDate(
      Math.min(
        input.getDate(),
        getDaysInMonth(date.getFullYear(), date.getMonth() + 1)
      )
    );
    return date;
  };

  useEffect(() => {
    set_loading(true);
    set_asmloading(true);
    set_CSMNewloading(true);
    get_asmNewPLI(moveMonthsForNewASM);
    get_CSMNewPLI(moveMonthsForNewCSM);
    get_data(moveMonths);
    get_data2(moveMonthsForASM);
  }, [moveMonthsForNewASM, moveMonths, moveMonthsForASM, moveMonthsForNewCSM]);

  let separator = "-";
  let sdnewDate = new Date();
  let sddate = sdnewDate.getDate();
  let sdmonth = sdnewDate.getMonth() + 1;
  let sdyear = sdnewDate.getFullYear();

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function processHeader(d) {
    debugger;
    const starting_date = new Date("2023-07-01");
    if (d >= starting_date) {
      if (d.getMonth() + 1 >= 7 && d.getMonth() + 1 <= 9) {
        setHeaderMonth1(
          "Jul-" + d.getFullYear() + " : " + "Sep-" + d.getFullYear()
        );
        setHeaderMonth(
          "Oct-" + d.getFullYear() + " : " + "Dec-" + d.getFullYear()
        );
      } else if (d.getMonth() + 1 >= 10 && d.getMonth() + 1 <= 12) {
        setHeaderMonth1(
          "Oct-" + d.getFullYear() + " : " + "Dec-" + d.getFullYear()
        );
        setHeaderMonth(
          "Jan-" +
          Number(d.getFullYear() + 1) +
          " : " +
          "Mar-" +
          Number(d.getFullYear() + 1)
        );
      } else if (d.getMonth() + 1 >= 1 && d.getMonth() + 1 <= 3) {
        setHeaderMonth1(
          "Jan-" + d.getFullYear() + " : " + "Mar-" + d.getFullYear()
        );
        setHeaderMonth(
          "Apr-" + d.getFullYear() + " : " + "Jun-" + d.getFullYear()
        );
      } else if (d.getMonth() + 1 >= 4 && d.getMonth() + 1 <= 6) {
        setHeaderMonth1(
          "Apr-" + d.getFullYear() + " : " + "Jun-" + d.getFullYear()
        );
        setHeaderMonth(
          "Jul-" +
          Number(d.getFullYear()) +
          " : " +
          "Sep-" +
          Number(d.getFullYear())
        );
      }
    } else {
      setHeaderMonth(
        months[d.getMonth()].toUpperCase() + " - " + d.getFullYear()
      );
      let mIndex = d.getMonth() == 0 ? 11 : d.getMonth() - 1;
      let mYear = d.getMonth() == 0 ? d.getFullYear() - 1 : d.getFullYear();
      setHeaderMonth1(months[mIndex].toUpperCase() + " - " + mYear);
    }
  }

  function processCSMNewHeader(d) {
    const starting_date = new Date("2010-07-01");
    if (d >= starting_date) {
      if (d.getMonth() + 1 >= 7 && d.getMonth() + 1 <= 9) {
        setCSMNewHeaderMonth1(
          "Jul-" + d.getFullYear() + " : " + "Sep-" + d.getFullYear()
        );
        setCSMNewHeaderMonth(
          "Oct-" + d.getFullYear() + " : " + "Dec-" + d.getFullYear()
        );
      } else if (d.getMonth() + 1 >= 10 && d.getMonth() + 1 <= 12) {
        setCSMNewHeaderMonth1(
          "Oct-" + d.getFullYear() + " : " + "Dec-" + d.getFullYear()
        );
        setCSMNewHeaderMonth(
          "Jan-" +
          Number(d.getFullYear() + 1) +
          " : " +
          "Mar-" +
          Number(d.getFullYear() + 1)
        );
      } else if (d.getMonth() + 1 >= 1 && d.getMonth() + 1 <= 3) {
        setCSMNewHeaderMonth1(
          "Jan-" + d.getFullYear() + " : " + "Mar-" + d.getFullYear()
        );
        setCSMNewHeaderMonth(
          "Apr-" + d.getFullYear() + " : " + "Jun-" + d.getFullYear()
        );
      } else if (d.getMonth() + 1 >= 4 && d.getMonth() + 1 <= 6) {
        setCSMNewHeaderMonth1(
          "Apr-" + d.getFullYear() + " : " + "Jun-" + d.getFullYear()
        );
        setCSMNewHeaderMonth(
          "Jul-" +
          Number(d.getFullYear()) +
          " : " +
          "Sep-" +
          Number(d.getFullYear())
        );
      }
    } else {
      setCSMNewHeaderMonth(
        months[d.getMonth()].toUpperCase() + " - " + d.getFullYear()
      );
      let mIndex = d.getMonth() == 0 ? 11 : d.getMonth() - 1;
      let mYear = d.getMonth() == 0 ? d.getFullYear() - 1 : d.getFullYear();
      setCSMNewHeaderMonth1(months[mIndex].toUpperCase() + " - " + mYear);
    }
  }


  function processAsmNewHeader(d) {
    const starting_date = new Date("2010-07-01");
    if (d >= starting_date) {
      if (d.getMonth() + 1 >= 7 && d.getMonth() + 1 <= 9) {
        setAsmNewHeaderMonth1(
          "Jul-" + d.getFullYear() + " : " + "Sep-" + d.getFullYear()
        );
        setAsmNewHeaderMonth(
          "Oct-" + d.getFullYear() + " : " + "Dec-" + d.getFullYear()
        );
      } else if (d.getMonth() + 1 >= 10 && d.getMonth() + 1 <= 12) {
        setAsmNewHeaderMonth1(
          "Oct-" + d.getFullYear() + " : " + "Dec-" + d.getFullYear()
        );
        setAsmNewHeaderMonth(
          "Jan-" +
          Number(d.getFullYear() + 1) +
          " : " +
          "Mar-" +
          Number(d.getFullYear() + 1)
        );
      } else if (d.getMonth() + 1 >= 1 && d.getMonth() + 1 <= 3) {
        setAsmNewHeaderMonth1(
          "Jan-" + d.getFullYear() + " : " + "Mar-" + d.getFullYear()
        );
        setAsmNewHeaderMonth(
          "Apr-" + d.getFullYear() + " : " + "Jun-" + d.getFullYear()
        );
      } else if (d.getMonth() + 1 >= 4 && d.getMonth() + 1 <= 6) {
        setAsmNewHeaderMonth1(
          "Apr-" + d.getFullYear() + " : " + "Jun-" + d.getFullYear()
        );
        setAsmNewHeaderMonth(
          "Jul-" +
          Number(d.getFullYear()) +
          " : " +
          "Sep-" +
          Number(d.getFullYear())
        );
      }
    } else {
      setAsmNewHeaderMonth(
        months[d.getMonth()].toUpperCase() + " - " + d.getFullYear()
      );
      let mIndex = d.getMonth() == 0 ? 11 : d.getMonth() - 1;
      let mYear = d.getMonth() == 0 ? d.getFullYear() - 1 : d.getFullYear();
      setAsmNewHeaderMonth1(months[mIndex].toUpperCase() + " - " + mYear);
    }
  }

  let monthIndex = sdnewDate.getMonth() - 1 == 0 ? 11 : sdnewDate.getMonth();
  let monthIndex1 = sdnewDate.getMonth() - 2 == 0 ? 10 : sdnewDate.getMonth();
  let monthIndex2 = sdnewDate.getMonth() - 3 == 0 ? 9 : sdnewDate.getMonth();
  let sddmonth = months[sdnewDate.getMonth()].toUpperCase();
  let sddmonth1 = months[monthIndex].toUpperCase();
  let sddmonth2 = months[monthIndex1].toUpperCase();
  let sddmonth3 = months[monthIndex2].toUpperCase();
  let sddyear = sdnewDate.getFullYear();

  let fsdate = `${sdyear}${separator}${sdmonth < 10 ? `0${sdmonth}` : `${sdmonth}`
    }${separator}${sddate < 10 ? `0${sddate}` : `${sddate}`}`;

  function get_data(m) {
    debugger;
    var d = new Date();
    d = addMonths(d, m);

    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";

    processHeader(d);

    axios
      .get(
        "yusuf/salespolicy?is_paid=" +
        paidornot +
        "&type=" +
        type +
        "&cce_name=" +
        user +
        "&emp_id=" +
        emp_id +
        "&month=" +
        date +
        ""
      )
      .then(function (response) {
        //alert(JSON.stringify(response.data))
        //alert( JSON.stringify(response.data[0]))
        set_header(Object.keys(response.data[0]));
        //console.log(response.data)
        set_data_list(response.data);
        set_loading(false);
        console.log(header);
        //alert(JSON.stringify(response.data))
        // set_city(response.data)
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function get_CSMNewPLI(m) {
    debugger;
    var d = new Date();
    d = addMonths(d, m);

    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    processCSMNewHeader(d);
    axios
      .get(
        "yusuf/CSMNewPolicy?isPaidAsm=" +
        isPaidAsm +
        "&date=" +
        date +
        "&isPrevious=" +
        isPrevious +
        ""
      )
      .then(function (response) {
        setCSMNewData(response.data);
        set_CSMNewloading(false);
        // Check if result has no data, then show 'No records found'
        const result = response.data;
        if (result.length === 0) {
          setShowNoRecords(true);
        } else {
          setShowNoRecords(false); // Reset to false if data is available
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }


  function get_asmNewPLI(m) {
    debugger;
    var d = new Date();
    d = addMonths(d, m);

    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    processAsmNewHeader(d);
    axios
      .get(
        "yusuf/ASMNewPolicy?isPaidAsm=" +
        isPaidAsm +
        "&date=" +
        date +
        "&isPrevious=" +
        isPrevious +
        ""
      )
      .then(function (response) {
        setData(response.data);
        set_asmloading(false);
        // Check if result has no data, then show 'No records found'
        const result = response.data;
        if (result.length === 0) {
          setShowNoRecords(true);
        } else {
          setShowNoRecords(false); // Reset to false if data is available
        }
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function get_data2(m) {
    var d = new Date();
    d = addMonths(d, m);
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    // setHeaderMonth(
    //   months[d.getMonth()].toUpperCase() + " - " + d.getFullYear()
    // );
    let mIndex = d.getMonth() == 0 ? 11 : d.getMonth() - 1;
    let mYear = d.getMonth() == 0 ? d.getFullYear() - 1 : d.getFullYear();

    // setHeaderMonth1(months[mIndex].toUpperCase() + " - " + mYear);

    axios
      .get("yusuf/salespolicyASM?ispaid=" + paidornot + "&month=" + date + "")
      .then(function (response) {
        //alert(JSON.stringify(response.data))
        //alert( JSON.stringify(response.data[0]))
        set_header2(Object.keys(response.data[0]));
        set_data_list2(response.data);
        //alert(JSON.stringify(response.data))
        // set_city(response.data)
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`
      }${separator}${year}`;
  }

  async function Search_click(m) {
    set_data_list([]);
    set_loading(true);

    var d = new Date();
    d = addMonths(d, m);
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    // setHeaderMonth(
    //   months[d.getMonth()].toUpperCase() + " - " + d.getFullYear()
    // );
    // let mIndex = d.getMonth() == 0 ? 11 : d.getMonth() - 1;
    // let mYear = d.getMonth() == 0 ? d.getFullYear() - 1 : d.getFullYear();
    // setHeaderMonth1(months[mIndex].toUpperCase() + " - " + mYear);

    processHeader(d);

    let { data } = await axios.get(
      "yusuf/salespolicy?is_paid=" +
      paidornot +
      "&type=" +
      type +
      "&cce_name=" +
      user +
      "&emp_id=" +
      emp_id +
      "&month=" +
      date +
      ""
    );
    if (data.length > 0) {
      set_header(Object.keys(data[0]));
      set_data_list(data);
    } else {
      set_data_list([]);
    }
    set_loading(false);
  }

  async function Search_click2(m) {
    set_data_list2([]);
    set_loading(true);
    var d = new Date();
    d = addMonths(d, m);
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();
    let date = curr_year + "-" + curr_month + "-01";
    // setHeaderMonth(
    //   months[d.getMonth()].toUpperCase() + " - " + d.getFullYear()
    // );
    // let mIndex = d.getMonth() == 0 ? 11 : d.getMonth() - 1;
    // let mYear = d.getMonth() == 0 ? d.getFullYear() - 1 : d.getFullYear();

    // setHeaderMonth1(months[mIndex].toUpperCase() + " - " + mYear);

    let { data } = await axios.get(
      "yusuf/salespolicyASM?ispaid=" + paidornot + "&month=" + date + ""
    );
    if (data.length > 0) {
      set_header2(Object.keys(data[0]));
      set_data_list2(data);
    } else {
      set_data_list2([]);
    }
  }

  function new_csm_cell_click(empid, month, name) {
    set_user(name);
    set_CSMNewloading(true);
    axios
      .get(
        "yusuf/CSMNewPolicyOnClikc?is_paid=" +
        paidornot +
        "&type=" +
        1 +
        "&emp_id=" +
        empid +
        "&month=" +
        month +
        ""
      )
      // axios.get('yusuf/spSalesPolicyOnclick?ispaid=' + paidornot + '&cce=' + u + '&header=' + m + '')
      .then(function (response) {
        set_CSMNewloading(false);
        if (response.data.length > 0) {
          // alert(JSON.stringify(response.data))
          // set_header(Object.keys(response.data[0]))
          cell_cell_data(response.data);
          // setOpen(true);
          setOpennewCSM(true);
          set_CSMNewloading(false);
        } else {
          alert("NO DATA");
        }
        set_CSMNewloading(false);
      })
      .catch(function (error) {
        alert(error);
      });


  }

  function cell_onclick(u, m, t) {
    if (m == "total") {
      return;
    }
    set_month(m);
    set_user(u);
    set_type(t);
    set_loading(true);
    // let js_start = new Date(sd);
    // let js_end = new Date(ed);
    // let sd_ = js_start.getMonth() + 1 + "/" + js_start.getDate() + "/" + js_start.getFullYear()
    // let ed_ = js_end.getMonth() + 1 + "/" + js_end.getDate() + "/" + js_end.getFullYear()

    // axios.get('yusuf/cce_conversionOnClick?sd=' + sd_ + '&ed=' + ed_ + '&user=' + u + '&monyear=' + m + '&country=' + country_name + '&city=' + city_id + '&vendor=' + vendor_id + '&optype=' + type + '&region=' + country_type + '&geo=' + selected_geo + '&google_lead=' + lead_type + '&course_type=' + fl_type + '&lead_type=' + lsf_type + '&dayofweek=' + day + '&start_time=' + start_time + '&end_time=' + end_time + '&weekends=' + weekends + '&holidays=' + holidays + '')
    axios
      .get(
        "yusuf/salespolicy?is_paid=" +
        paidornot +
        "&type=" +
        t +
        "&cce_name=" +
        u +
        "&month=" +
        m +
        ""
      )
      // axios.get('yusuf/spSalesPolicyOnclick?ispaid=' + paidornot + '&cce=' + u + '&header=' + m + '')
      .then(function (response) {
        set_loading(false);
        if (response.data.length > 0) {
          // alert(JSON.stringify(response.data))
          // set_header(Object.keys(response.data[0]))
          cell_cell_data(response.data);
          setOpen(true);
          set_loading(false);
        } else {
          alert("NO DATA");
        }
        set_loading(false);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function cell_onclick2(u, m) {
    if (m == "total") {
      return;
    }
    set_month(m);
    set_user(u);
    set_loading(true);
    // let js_start = new Date(sd);
    // let js_end = new Date(ed);
    // let sd_ = js_start.getMonth() + 1 + "/" + js_start.getDate() + "/" + js_start.getFullYear()
    // let ed_ = js_end.getMonth() + 1 + "/" + js_end.getDate() + "/" + js_end.getFullYear()

    // axios.get('yusuf/cce_conversionOnClick?sd=' + sd_ + '&ed=' + ed_ + '&user=' + u + '&monyear=' + m + '&country=' + country_name + '&city=' + city_id + '&vendor=' + vendor_id + '&optype=' + type + '&region=' + country_type + '&geo=' + selected_geo + '&google_lead=' + lead_type + '&course_type=' + fl_type + '&lead_type=' + lsf_type + '&dayofweek=' + day + '&start_time=' + start_time + '&end_time=' + end_time + '&weekends=' + weekends + '&holidays=' + holidays + '')
    axios
      .get(
        "yusuf/spASMSalesPolicyOnclick?ispaid=" +
        paidornot +
        "&cce=" +
        u +
        "&header=" +
        m +
        ""
      )
      .then(function (response) {
        set_loading(false);
        if (response.data.length > 0) {
          // alert(JSON.stringify(response.data))
          //set_header(Object.keys(response.data[0]))
          cell_cell_data(response.data);
          setOpen(true);
          set_loading(false);
        } else {
          alert("NO DATA");
        }
        set_loading(false);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "100vh",
      }}
      className="mainDivCV"
    >
      {/* DATA SECTION */}
      {/* <div style={{ width: '100vw', height: '100vh', display: (emp_id == 2131 || emp_id == 1687 || emp_id == 1460) ? 'flex' : 'none', flexDirection: 'column', backgroundColor: '#ffffff' }}> */}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          flexDirection: "column",
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            padding: "1.5%",
            height: "8vh",
            backgroundColor: "#fafafb",
            width: "",
            WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
            MozBoxShadow: "0 8px 6px -6px #d5d4d6",
            boxShadow: "0 8px 6px -6px #d5d4d6",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "100%", marginTop: "-0.3%" }}>
              <span
                style={{
                  marginLeft: "2%",
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Sales Policy
              </span>
              <span
                style={{
                  marginLeft: "2%",
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                <select
                  className="btn border-success"
                  style={{ width: "15%", fontSize: "100%" }}
                  onChange={handleSelectChange}
                  value={cceorasm}
                >
                  <option value="cce" style={{ fontSize: "100%" }}>
                    CSM
                  </option>
                  <option value="csmNewPLI" style={{ fontSize: "100%" }}>
                    CSM (New)
                  </option>
                  <option value="asm" style={{ fontSize: "100%" }}>
                    ASM PLI (Old)
                  </option>
                  <option value="asmNewPLI" style={{ fontSize: "100%" }}>
                    ASM PLI (New)
                  </option>
                </select>
              </span>
              <span style={{ marginLeft: "25%", fontWeight: "bold" }}>
                PLI Includes Nudge & Bad Debt
              </span>
            </div>
            <div
              className="SPradioDiv"
              style={{ width: "45%", marginTop: "-0.5%" }}
            >
              <Radio
                style={{
                  marginLeft: cceorasm == "asm" ? "40%" : "5%",
                  display: cceorasm == "asm" ? "none" : "inline-block",
                }}
                checked={paidornot == "nonpaid" ? true : false}
                onChange={() => {
                  set_paidornot("nonpaid");
                  set_isPaidAsm(0);
                }}
                label="As per delivery"
              />
              <Radio
                style={{
                  marginLeft: "5%",
                  display: cceorasm == "asm" ? "none" : "inline-block",
                }}
                checked={paidornot == "ispaid" ? true : false}
                onChange={() => {
                  set_paidornot("ispaid");
                  set_isPaidAsm(1);
                }}
                label="As per collection"
              />
              <Button
                onClick={() => {
                  debugger;
                  if (cceorasm === "asmNewPLI") {
                    set_asmloading(true);
                    get_asmNewPLI(moveMonthsForNewASM);
                  }
                  else if (cceorasm == "csmNewPLI") {
                    set_CSMNewloading(true);
                    get_CSMNewPLI(moveMonthsForNewCSM);
                  }
                  else {
                    Search_click(moveMonths);
                    Search_click2(moveMonthsForASM);
                  }
                }}
                color="teal"
                style={{
                  marginLeft: "2%",
                  display: cceorasm == "asm" ? "none" : "inline-block",
                }}
              >
                Display
              </Button>
            </div>
          </div>
        </div>

        {/*CSM PLI (OLD)*/}

        <div style={{ display: cceorasm == "cce" ? "block" : "none" }}>
          <div
            style={{
              display: loading ? "none" : "flex",
              padding: "1% 1%",
              textAlign: "center",
            }}
          >
            <span style={{ width: "100%", fontSize: "140%" }}>CCE's</span>
          </div>

          <div
            style={{
              display: loading ? "none" : "flex",
              padding: "0.2%",
              overflow: "scroll",
              height: "86vh",
            }}
          >
            {/* <DynamicDataTable style={{border:2}} rows={data_list} /> */}

            <span style={{ width: "100%", overflowY: "scroll" }}>
              <table
                id="cconversion"
                className="table cvvtable"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                    <th></th>
                    <th colSpan="6">
                      <Icon
                        onClick={() => {
                          debugger;
                          headerMonth.includes(":")
                            ? setMoveMonths(moveMonths + 6)
                            : setMoveMonths(moveMonths + 2);
                        }}
                        size="big"
                        color="teal"
                        name="caret left"
                        style={{ cursor: "pointer" }}
                      />
                      {headerMonth}
                    </th>
                    {/* <th colSpan="4">{sddmonth1 + " - " + sddyear}</th> */}
                    {/* <th colSpan="4">{sddmonth2 + " - " + sddyear}</th> */}
                    <th colSpan="6">
                      {headerMonth1}
                      <Icon
                        onClick={() => {
                          debugger;
                          headerMonth1.includes(":") &&
                            !headerMonth1.includes("Jul-2023")
                            ? setMoveMonths(moveMonths - 6)
                            : setMoveMonths(moveMonths - 2);
                        }}
                        size="big"
                        color="teal"
                        name="caret right"
                        style={{ cursor: "pointer" }}
                      />
                    </th>
                  </tr>
                  <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                    {header.map((key, index) => {
                      if (key.toUpperCase() == "CCE_NAME") {
                        return (
                          <th className="text-center" key={index}>
                            EMPLOYEE
                          </th>
                        );
                      } else if (key.includes("nr1") || key.includes("nr2")) {
                        return (
                          <th className="text-center" key={index}>
                            NR
                          </th>
                        );
                      } else if (
                        key.includes("payout1") ||
                        key.includes("payout2")
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            PAYOUT
                          </th>
                        );
                      } else if (
                        key.includes("salary1") ||
                        key.includes("salary2")
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            SALARY
                          </th>
                        );
                      } else if (
                        key.includes("Extra_amount1") ||
                        key.includes("Extra_amount2")
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            EXTRA AMT.
                          </th>
                        );
                      } else if (
                        key.includes("Paid") ||
                        key.includes("Paid1")
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            PAID
                          </th>
                        );
                      } else if (
                        key.includes("Balance") ||
                        key.includes("Balance1")
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            BALANCE
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>

                <tbody>
                  {data_list.map((row, ii) => (
                    <tr key={ii}>
                      {Object.values(row).map((rowValue, i) => {
                        if (i == 0) {
                          return (
                            <td key={i} className="text-center">
                              {rowValue}
                            </td>
                          );
                        }
                        // else if (i == 2 || i == 3 || i == 6 || i == 7 || i == 10 || i == 11 || i == 14 || i == 15) {
                        else if (i == 3 || i == 9) {
                          return (
                            <td className="text-center" style={{}} key={i}>
                              {rowValue}
                              {/* <span style={{ color: ((rowValue.includes('-'))) ? '#f21111' : ''}}>{rowValue}</span> */}
                            </td>
                          );
                        } else if (i == 5 || i == 6 || i == 11 || i == 12) {
                          return (
                            <td className="text-center" style={{}} key={i}>
                              {rowValue}
                              {/* <span style={{ color: ((rowValue.includes('-'))) ? '#f21111' : ''}}>{rowValue}</span> */}
                            </td>
                          );
                        }
                        // else if (i == 4 || i == 8 || i == 12 || i == 16) {
                        else if (i == 4 || i == 10) {
                          return (
                            // <td className="text-center" style={{ color: 'black' }} key={i}>
                            <td
                              className="text-center"
                              style={{
                                color:
                                  rowValue.toString().indexOf("-") < 0
                                    ? "green"
                                    : "red",
                              }}
                              key={i}
                            >
                              {rowValue}
                              {/* <span style={{ color: ((rowValue.includes('-'))) ? '#f21111' : ''}}>{rowValue}</span> */}
                            </td>
                          );
                        } else if (
                          rowValue &&
                          (i == 1 || i == 2 || i == 7 || i == 8) &&
                          data_list[ii].empname != "-"
                        ) {
                          return (
                            <td
                              className="text-center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  header[i].toString().indexOf("nr1_") == 0 ||
                                  header[i].toString().indexOf("nr2_") == 0
                                ) {
                                  cell_onclick(
                                    data_list[ii].cce_name,
                                    header[i],
                                    1
                                  );
                                } else {
                                  cell_onclick(
                                    data_list[ii].cce_name,
                                    header[i],
                                    2
                                  );
                                }
                                //alert(data_list[ii].cce + '--'+header[i])
                                //alert(header[i])
                              }}
                              key={i}
                            >
                              {rowValue}
                              {/* <span style={{ color: ((rowValue.includes('-'))) ? '#f21111' : ''}}>{rowValue}</span> */}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </tbody>

                {/* {data_list.map((d) => {
                            return (
                                <Row>
                                    <Cell>
                                        E:{d.total.split('/')[0]}
                                        <br></br>
                                        R:{d.total.split('/')[1]}
                                        <br></br>
                                        C:{((d.total.split('/')[1]) / (d.total.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>{d.UserName}</Cell>
                                    <Cell>
                                        E:{d.jan.split('/')[0]}
                                        <br></br>
                                        R:{d.jan.split('/')[1]}
                                        <br></br>
                                        C:{((d.jan.split('/')[1]) / (d.jan.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.feb.split('/')[0]}
                                        <br></br>
                                        R:{d.feb.split('/')[1]}
                                        <br></br>
                                        C:{((d.feb.split('/')[1]) / (d.feb.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.mar.split('/')[0]}
                                        <br></br>
                                        R:{d.mar.split('/')[1]}
                                        <br></br>
                                        C:{((d.mar.split('/')[1]) / (d.mar.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.apr.split('/')[0]}
                                        <br></br>
                                        R:{d.apr.split('/')[1]}
                                        <br></br>
                                        C:{((d.apr.split('/')[1]) / (d.apr.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.may.split('/')[0]}
                                        <br></br>
                                        R:{d.may.split('/')[1]}
                                        <br></br>
                                        C:{((d.may.split('/')[1]) / (d.may.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.jun.split('/')[0]}
                                        <br></br>
                                        R:{d.jun.split('/')[1]}
                                        <br></br>
                                        C:{((d.jun.split('/')[1]) / (d.jun.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.jul.split('/')[0]}
                                        <br></br>
                                        R:{d.jul.split('/')[1]}
                                        <br></br>
                                        C:{((d.jul.split('/')[1]) / (d.jul.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>


                                </Row>
                            )
                        })} */}
              </table>
            </span>
          </div>
        </div>


        {/*CSM PLI (NEW) */}

        <div style={{ display: cceorasm == "csmNewPLI" ? "block" : "none" }}>
          <div
            style={{
              display: CSMNewloading ? "none" : "flex",
              padding: "1% 1%",
              textAlign: "center",
            }}
          >
            <span style={{ width: "100%", fontSize: "140%" }}>
              CSM PLI (New) - “Entitlement to new CSM PLI as per policy notified by HR”
            </span>
          </div>

          <div
            style={{
              display: CSMNewloading ? "none" : "flex",
              padding: "0.2%",
              overflow: "scroll",
              height: "86vh",
            }}
          >
            <span style={{ width: "100%", overflowY: "scroll" }}>
              <table
                id="cconversion"
                className="table cvvtable"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th></th>

                    <th colSpan="12">
                      <Icon
                        onClick={() => {
                          setmoveMonthsForNewCSM(moveMonthsForNewCSM + 3);
                          set_isPrevious(0);
                        }}
                        size="big"
                        color="teal"
                        name="caret left"
                        style={{ cursor: "pointer" }}
                      />
                      {CSMNewheaderMonth1}
                      <Icon
                        onClick={() => {
                          setmoveMonthsForNewCSM(moveMonthsForNewCSM - 3);
                          set_isPrevious(1);
                        }}
                        size="big"
                        color="teal"
                        name="caret right"
                        style={{ cursor: "pointer" }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>CSM</th>
                    <th>Target</th>
                    <th>Achieved</th>
                    <th>% Achieved</th>
                    <th>Incentive</th>

                  </tr>
                </thead>

                <tbody>
                  {showNoRecords ? (
                    <tr>
                      <td
                        colSpan="13"
                        style={{ textAlign: "center" }}
                        className="no-records"
                      >
                        No records found
                      </td>
                    </tr>
                  ) : (
                    CSMNewData.map((row, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <strong>{row.Name}</strong>
                        </td>
                        <td className="text-center">{row.target_given}</td>
                        <td className="text-center"
                          onClick={() => new_csm_cell_click(row.emp_id, row.month, row.Name)}
                        >{row.target_achieved}</td>
                        <td className="text-center">
                          {row.percentage_achieved}%
                        </td>
                        <td
                          className="text-center"
                          onClick={() =>
                            togglePopupForNewCSM(
                              parseInteger(row.target_given),
                              row.percentage_achieved,
                              parseInteger(row.pli),
                              parseInteger(row.csm_plus_incentive),
                              parseInteger(row.target_achieved),
                            )
                          }
                        >
                          {row.pli}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </span>
          </div>
          {
            <div>
              <Modal
                open={csmModalopen}
                onClose={() => setcsmModalOpen(false)}
                onOpen={() => setcsmModalOpen(true)}
                style={{ marginTop: "0px", marginLeft: "10%", width: "80%" }}
              >
                <Modal.Header>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "20%" }}>
                      <div>{user}</div>
                      {/* <div>{month}</div> */}
                    </div>
                    <div
                      style={{
                        width: "60%",
                        textAlign: "center",
                        fontSize: "large",
                        paddingTop: "1%",
                      }}
                    >
                      INCENTIVE CALCULATION
                    </div>
                    <div
                      style={{ width: "10%", position: "absolute", right: 10 }}
                    >
                      <Button
                        onClick={() => {
                          setcsmModalOpen(false);
                          resetStates();
                        }}
                        primary
                      >
                        Close <Icon style={{ marginLeft: 10 }} name="close" />
                      </Button>
                    </div>
                  </div>
                </Modal.Header>

                <Modal.Content scrolling>
                  <Modal.Description>
                    <Table className="cconversion" celled fixed singleLine>
                      <Table.Header style={{ display: "revert" }}>
                        <Table.Row>
                          <Table.HeaderCell>STEPS</Table.HeaderCell>
                          <Table.HeaderCell>VALUE</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <center>
                              I. 1.25% of True NR upto 75% of target
                            </center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedFirstPartIncentive.toLocaleString(
                                "en-IN"
                              )}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>
                              II. 2.5% of True NR 75-100 of target%
                            </center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedSecondPartIncentive.toLocaleString(
                                "en-IN"
                              )}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>III. 5% of True NR beyond 100%</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedThirdPartIncentive.toLocaleString(
                                "en-IN"
                              )}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>
                              IV. 2.5% of CSM+ Incentive
                            </center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedReportingASM.toLocaleString("en-IN")}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>Icentive</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedIncentive.toLocaleString("en-IN")}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          }
        </div>



        {/*ASM PLI OLD */}
        <div
          style={{
            display: cceorasm == "asm" ? "block" : "none",
            marginTop: "1%",
          }}
        >
          <div
            style={{
              display: loading ? "none" : "flex",
              padding: "1% 1%",
              textAlign: "center",
            }}
          >
            <span style={{ width: "100%", fontSize: "140%" }}>
              <Icon
                onClick={() => {
                  setMoveMonthsForASM(moveMonthsForASM + 2);
                }}
                size="big"
                color="teal"
                name="caret left"
                style={{ cursor: "pointer" }}
              />
              ASM PLI (Old)
              <Icon
                onClick={() => {
                  setMoveMonthsForASM(moveMonthsForASM - 2);
                }}
                size="big"
                color="teal"
                name="caret right"
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>

          <div
            style={{
              display: loading ? "none" : "flex",
              padding: "0.2%",
              overflow: "scroll",
              height: "100vh",
              marginTop: "1%",
            }}
          >
            {/* <DynamicDataTable style={{border:2}} rows={data_list} /> */}

            <span style={{ width: "100%", overflowY: "scroll" }}>
              <table
                id="cconversion"
                className="table cvvvtable ssscvtable"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                    {header2.map((key, index) => {
                      if (key.toUpperCase() == "MANAGER") {
                        return (
                          <th className="text-center" key={index}>
                            EMPLOYEE
                          </th>
                        );
                      } else if (
                        key.toUpperCase() == "5% OF GROWTH1" ||
                        key.toUpperCase() == "5% OF GROWTH2" ||
                        key.toUpperCase() == "5% OF GROWTH3" ||
                        key.toUpperCase() == "5% OF GROWTH4"
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            5% of Growth
                          </th>
                        );
                      } else if (
                        key.toUpperCase() == "PAYOUT1" ||
                        key.toUpperCase() == "PAYOUT2" ||
                        key.toUpperCase() == "PAYOUT3" ||
                        key.toUpperCase() == "PAYOUT4"
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            PAYOUT
                          </th>
                        );
                      } else if (
                        key.toUpperCase() == "PAYOUT1D" ||
                        key.toUpperCase() == "PAYOUT2D" ||
                        key.toUpperCase() == "PAYOUT3D" ||
                        key.toUpperCase() == "PAYOUT4D"
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            PAYOUT / DEFICIT
                          </th>
                        );
                      } else if (
                        key.toUpperCase() == "PAID" ||
                        key.toUpperCase() == "PAID1"
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            PAID
                          </th>
                        );
                      } else if (
                        key.toUpperCase() == "BALANCE" ||
                        key.toUpperCase() == "BALANCE1"
                      ) {
                        return (
                          <th className="text-center" key={index}>
                            BALANCE
                          </th>
                        );
                      }
                      // else if (key.toUpperCase() == "S1" || key.toUpperCase() == "S2" || key.toUpperCase() == "S3" || key.toUpperCase() == "S4") {
                      //     return <th className="text-center" key={index}>SALARY</th>
                      // }
                      // else if (key.toUpperCase() == "EA1" || key.toUpperCase() == "EA2" || key.toUpperCase() == "EA3" || key.toUpperCase() == "EA4") {
                      //     return <th className="text-center" key={index}>EXTRA AMT.</th>
                      // }
                      else {
                        return (
                          <th className="text-center" key={index}>
                            {key.toUpperCase()}
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>

                <tbody>
                  {data_list2.map((row, ii) => (
                    <tr key={ii}>
                      {Object.values(row).map((rowValue, i) => {
                        if (i == 3 || i == 8) {
                          // return (<td key={i} className="text-center" style={{ color: rowValue.toString().indexOf('-') < 0 ? 'green' : 'red' }}>
                          return (
                            <td key={i} className="text-center">
                              {/* {rowValue.toString().indexOf('-') < 0 ? rowValue : 0} */}
                              {rowValue}
                            </td>
                          );
                        } else {
                          return (
                            <td key={i} className="text-center">
                              {rowValue}
                            </td>
                          );
                        }
                        // else if (i == 2 || i == 3 || i == 4 || i == 6 || i == 7 || i == 8 || i == 10 || i == 11 || i == 12 || i == 14 || i == 15 || i == 16) {
                        //     return (
                        //         <td className="text-center" style={{}} key={i}>
                        //             {rowValue}
                        //             {/* <span style={{ color: ((rowValue.includes('-'))) ? '#f21111' : ''}}>{rowValue}</span> */}
                        //         </td>
                        //     )
                        // }
                        // else if (rowValue && data_list2[ii].Manager != "-") {
                        //     return (
                        //         <td className="text-center" style={{ cursor: 'pointer' }}
                        //         // onClick={() => {
                        //         //     cell_onclick2(data_list2[ii].Manager, header2[i])
                        //         // }}
                        //         key={i}>
                        //             {rowValue}
                        //             {/* <span style={{ color: ((rowValue.includes('-'))) ? '#f21111' : ''}}>{rowValue}</span> */}
                        //         </td>
                        //     )
                        // }
                        // else {
                        //     return (
                        //         <td key={i} className="text-center">
                        //             -
                        //         </td>
                        //     )
                        // }
                      })}
                    </tr>
                  ))}
                </tbody>

                {/* {data_list.map((d) => {
                            return (
                                <Row>
                                    <Cell>
                                        E:{d.total.split('/')[0]}
                                        <br></br>
                                        R:{d.total.split('/')[1]}
                                        <br></br>
                                        C:{((d.total.split('/')[1]) / (d.total.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>{d.UserName}</Cell>
                                    <Cell>
                                        E:{d.jan.split('/')[0]}
                                        <br></br>
                                        R:{d.jan.split('/')[1]}
                                        <br></br>
                                        C:{((d.jan.split('/')[1]) / (d.jan.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.feb.split('/')[0]}
                                        <br></br>
                                        R:{d.feb.split('/')[1]}
                                        <br></br>
                                        C:{((d.feb.split('/')[1]) / (d.feb.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.mar.split('/')[0]}
                                        <br></br>
                                        R:{d.mar.split('/')[1]}
                                        <br></br>
                                        C:{((d.mar.split('/')[1]) / (d.mar.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.apr.split('/')[0]}
                                        <br></br>
                                        R:{d.apr.split('/')[1]}
                                        <br></br>
                                        C:{((d.apr.split('/')[1]) / (d.apr.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.may.split('/')[0]}
                                        <br></br>
                                        R:{d.may.split('/')[1]}
                                        <br></br>
                                        C:{((d.may.split('/')[1]) / (d.may.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.jun.split('/')[0]}
                                        <br></br>
                                        R:{d.jun.split('/')[1]}
                                        <br></br>
                                        C:{((d.jun.split('/')[1]) / (d.jun.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.jul.split('/')[0]}
                                        <br></br>
                                        R:{d.jul.split('/')[1]}
                                        <br></br>
                                        C:{((d.jul.split('/')[1]) / (d.jul.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>


                                </Row>
                            )
                        })} */}
              </table>
            </span>
          </div>
        </div>

        {/* ASM NEW PLI */}
        <div style={{ display: cceorasm == "asmNewPLI" ? "block" : "none" }}>
          <div
            style={{
              display: asmloading ? "none" : "flex",
              padding: "1% 1%",
              textAlign: "center",
            }}
          >
            <span style={{ width: "100%", fontSize: "140%" }}>
              ASM PLI (New)
            </span>
          </div>

          <div
            style={{
              display: asmloading ? "none" : "flex",
              padding: "0.2%",
              overflow: "scroll",
              height: "86vh",
            }}
          >
            <span style={{ width: "100%", overflowY: "scroll" }}>
              <table
                id="cconversion"
                className="table cvvtable"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan="6">
                      <Icon
                        onClick={() => {
                          setMoveMonthsForNewASM(moveMonthsForNewASM + 6);
                          set_isPrevious(0);
                        }}
                        size="big"
                        color="teal"
                        name="caret left"
                        style={{ cursor: "pointer" }}
                      />
                      {asmNewheaderMonth}
                    </th>
                    <th colSpan="6">
                      {asmNewheaderMonth1}
                      <Icon
                        onClick={() => {
                          setMoveMonthsForNewASM(moveMonthsForNewASM - 6);
                          set_isPrevious(1);
                        }}
                        size="big"
                        color="teal"
                        name="caret right"
                        style={{ cursor: "pointer" }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Manager</th>
                    <th>Target</th>
                    <th>Achieved</th>
                    <th>% Achieved</th>
                    <th>Salary</th>
                    <th>Incentive</th>
                    <th>Total PLI</th>
                    <th>Target</th>
                    <th>Achieved</th>
                    <th>% Achieved</th>
                    <th>Salary</th>
                    <th>Incentive</th>
                    <th>Total PLI</th>
                  </tr>
                </thead>

                <tbody>
                  {showNoRecords ? (
                    <tr>
                      <td
                        colSpan="13"
                        style={{ textAlign: "center" }}
                        className="no-records"
                      >
                        No records found
                      </td>
                    </tr>
                  ) : (
                    data.map((row, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <strong>{row.Q1_Manager}</strong>
                        </td>
                        <td className="text-center">{row.Q1_Target}</td>
                        <td className="text-center">{row.Q1_Achieved}</td>
                        <td className="text-center">
                          {row.Q1_Percentage_Achieved}%
                        </td>
                        <td className="text-center">
                          {emp_id === "88" || emp_id === "1460" || emp_id === "3456"
                            ? row.Q1_Salary
                            : 0}
                        </td>
                        <td
                          className="text-center"
                          onClick={() =>
                            togglePopup(
                              parseInteger(row.Q1_Target),
                              row.Q1_Percentage_Achieved,
                              parseInteger(row.Q1_Incentive),
                              parseInteger(row.Q1_Salary),
                              row.Q1_SelfIncentive
                            )
                          }
                        >
                          {row.Q1_Incentive}
                        </td>
                        <td className="text-center">
                          {(
                            parseInteger(row.Q1_Incentive) -
                            3 * parseInteger(row.Q1_Salary)
                          ).toLocaleString("en-IN")}
                        </td>
                        <td className="text-center">{row.Q4_Target}</td>
                        <td className="text-center">{row.Q4_Achieved}</td>
                        <td className="text-center">
                          {row.Q4_Percentage_Achieved}%
                        </td>
                        <td className="text-center">
                          {emp_id === "88" || emp_id === "1460" || emp_id === "3456"
                            ? row.Q4_Salary
                            : 0}
                        </td>
                        <td
                          className="text-center"
                          onClick={() =>
                            togglePopup(
                              parseInteger(row.Q4_Target),
                              row.Q4_Percentage_Achieved,
                              parseInteger(row.Q4_Incentive),
                              parseInteger(row.Q4_Salary),
                              row.Q4_SelfIncentive
                            )
                          }
                        >
                          {row.Q4_Incentive}
                        </td>
                        <td className="text-center">
                          {(
                            parseInteger(row.Q4_Incentive) -
                            3 * parseInteger(row.Q4_Salary)
                          ).toLocaleString("en-IN")}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </span>
          </div>
          {
            <div>
              <Modal
                open={asmModalopen}
                onClose={() => setasmModalOpen(false)}
                onOpen={() => setasmModalOpen(true)}
                style={{ marginTop: "0px", marginLeft: "10%", width: "80%" }}
              >
                <Modal.Header>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "20%" }}>
                      <div>{user}</div>
                      {/* <div>{month}</div> */}
                    </div>
                    <div
                      style={{
                        width: "60%",
                        textAlign: "center",
                        fontSize: "large",
                        paddingTop: "1%",
                      }}
                    >
                      INCENTIVE CALCULATION
                    </div>
                    <div
                      style={{ width: "10%", position: "absolute", right: 10 }}
                    >
                      <Button
                        onClick={() => {
                          setasmModalOpen(false);
                          resetStates();
                        }}
                        primary
                      >
                        Close <Icon style={{ marginLeft: 10 }} name="close" />
                      </Button>
                    </div>
                  </div>
                </Modal.Header>

                <Modal.Content scrolling>
                  <Modal.Description>
                    <Table className="cconversion" celled fixed singleLine>
                      <Table.Header style={{ display: "revert" }}>
                        <Table.Row>
                          <Table.HeaderCell>STEPS</Table.HeaderCell>
                          <Table.HeaderCell>VALUE</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <center>
                              I. 1.25% of True NR upto 75% of target
                            </center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedFirstPartIncentive.toLocaleString(
                                "en-IN"
                              )}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>
                              II. 2.5% of True NR 75-100 of target%
                            </center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedSecondPartIncentive.toLocaleString(
                                "en-IN"
                              )}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>III. 5% of True NR beyond 100%</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedThirdPartIncentive.toLocaleString(
                                "en-IN"
                              )}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>
                              IV. 50% of Incentive earned by reporting ASMs
                            </center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedReportingASM.toLocaleString("en-IN")}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <center>Icentive</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>
                              {selectedIncentive.toLocaleString("en-IN")}
                            </center>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          }
        </div>

        {/* LOADER */}
        <div
          style={{
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Loader active inline size="massive">
            getting data...
          </Loader>
        </div>

        {/* asmLOADER */}
        <div
          style={{
            display: asmloading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Loader active inline size="massive">
            getting data...
          </Loader>
        </div>

        {/* asmcsmLoaderLOADER */}
        <div
          style={{
            display: CSMNewloading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Loader active inline size="massive">
            getting data...
          </Loader>
        </div>

        <div>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{ marginTop: "0px", marginLeft: "10%", width: "80%" }}
          >
            <Modal.Header>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "20%" }}>
                  <div>{user}</div>
                  {/* <div>{month}</div> */}
                </div>
                <div
                  style={{
                    width: "60%",
                    textAlign: "center",
                    fontSize: "large",
                    paddingTop: "1%",
                  }}
                >
                  {month.toString().indexOf("nr") < 0
                    ? "PAYOUT CALCULATION"
                    : "NR CALCULATION"}
                </div>
                <div style={{ width: "10%", position: "absolute", right: 10 }}>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      set_type(0);
                    }}
                    primary
                  >
                    Close <Icon style={{ marginLeft: 10 }} name="close" />
                  </Button>
                </div>
              </div>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <Table className="cconversion" celled fixed singleLine>
                  <Table.Header
                    style={{ display: type == 1 ? "none" : "revert" }}
                  >
                    <Table.Row>
                      {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                      {/* <Table.HeaderCell>MonYear</Table.HeaderCell> */}
                      <Table.HeaderCell>Assignment</Table.HeaderCell>
                      <Table.HeaderCell>SCID</Table.HeaderCell>
                      <Table.HeaderCell>NR</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell>% PAYOUT</Table.HeaderCell>
                      <Table.HeaderCell>PAYOUT</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Header
                    style={{ display: type == 2 ? "none" : "revert" }}
                  >
                    <Table.Row>
                      {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                      {/* <Table.HeaderCell>MonYear</Table.HeaderCell> */}
                      <Table.HeaderCell>Assignment</Table.HeaderCell>
                      <Table.HeaderCell>NR</Table.HeaderCell>
                      <Table.HeaderCell>Month</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {cell_data.map((d) => {
                      if (type == 2) {
                        return (
                          <Table.Row>
                            {/* <Table.Cell>{d.Name}</Table.Cell> */}
                            {/* <Table.Cell><center>{d.month}</center></Table.Cell> */}
                            <Table.Cell>
                              <center>{d.assignment_id}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.scid}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.total_nr}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.type_name}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.payout_percent}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.payout}</center>
                            </Table.Cell>
                            {/* <Table.Cell><center>{d.total_nr}</center></Table.Cell>
                                                    <Table.Cell><center>{d.type}</center></Table.Cell>
                                                    <Table.Cell><center>{d.Remarks}</center></Table.Cell>
                                                    <Table.Cell><center>{d.PerPayout}</center></Table.Cell>
                                                    <Table.Cell><center>{d.Payout}</center></Table.Cell> */}
                          </Table.Row>
                        );
                      } else {
                        return (
                          <Table.Row>
                            {/* <Table.Cell>{d.Name}</Table.Cell> */}
                            {/* <Table.Cell><center>{d.month}</center></Table.Cell> */}
                            <Table.Cell>
                              <center>{d.assignment_id}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.nr}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.month}</center>
                            </Table.Cell>
                            <Table.Cell>
                              <center>{d.nr_type}</center>
                            </Table.Cell>
                            {/* <Table.Cell><center>{d.total_nr}</center></Table.Cell>
                                                    <Table.Cell><center>{d.type}</center></Table.Cell>
                                                    <Table.Cell><center>{d.Remarks}</center></Table.Cell>
                                                    <Table.Cell><center>{d.PerPayout}</center></Table.Cell>
                                                    <Table.Cell><center>{d.Payout}</center></Table.Cell> */}
                          </Table.Row>
                        );
                      }
                    })}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>



        <div>
          <Modal
            open={opennewCSM}
            onClose={() => setOpennewCSM(false)}
            onOpen={() => setOpennewCSM(true)}
            style={{ marginTop: "0px", marginLeft: "10%", width: "80%" }}
          >
            <Modal.Header>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "20%" }}>
                  <div>{user}</div>
                </div>
                <div
                  style={{
                    width: "60%",
                    textAlign: "center",
                    fontSize: "large",
                    paddingTop: "1%",
                  }}
                >
                  NR ACHIEVED
                </div>
                <div style={{ width: "10%", position: "absolute", right: 10 }}>
                  <Button
                    onClick={() => {
                      setOpennewCSM(false);
                      set_type(0);
                    }}
                    primary
                  >
                    Close <Icon style={{ marginLeft: 10 }} name="close" />
                  </Button>
                </div>
              </div>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <Table className="cconversion" celled fixed singleLine>
                  <Table.Header
                    style={{ display: type == 1 ? "none" : "revert" }}
                  >
                    <Table.Row>
                      {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                      {/* <Table.HeaderCell>MonYear</Table.HeaderCell> */}
                      <Table.HeaderCell>Assignment</Table.HeaderCell>
                      <Table.HeaderCell>SCID</Table.HeaderCell>
                      <Table.HeaderCell>NR</Table.HeaderCell>
                      <Table.HeaderCell>Month</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {cell_data.map((d) => {

                      return (
                        <Table.Row>
                          <Table.Cell>
                            <center>{d.assignment_id}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.scid}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.nr}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.month}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.type}</center>
                          </Table.Cell>

                        </Table.Row>
                      );

                    })}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>




      </div>

      {/* <div style={{ display: (emp_id == 2131 || emp_id == 1687 || emp_id == 1460) ? 'none' : 'flex', padding: '3%' }}>
                <h1 style={{ fontSize: '400%', color: 'rgba(21,243,1,1)' }}>Work in progress.......</h1>
            </div> */}
    </div>
  );
}

// {d.jan.split('/')[1]}
